import { GetStaticProps, NextPage } from 'next'
import React from 'react'
import { Layout } from '../components/layout/layout'
import { GlobalProps, withLayoutProps } from '../globals/layout'
import { REVALIDATION_FREQUENCY } from '../constants/revalidation'
import { Home, HOMEPAGE_QUERY, HomeProps } from '../templates/home'
import { HomepageQuery, HomepageQueryVariables } from '../templates/home/__generated__'
import { pageQuery } from '../utils'

export type HomepageProps = GlobalProps & {
  data: HomeProps
}

const Homepage: NextPage<HomepageProps> = ({ data, ...layoutProps }) => {
  return (
    <Layout { ...layoutProps }>
      <Home { ...data } />
    </Layout>
  )
}

export default Homepage

export const getStaticProps: GetStaticProps = async (context) => {
  const { data } = await pageQuery<HomepageQuery, HomepageQueryVariables>({
    query: HOMEPAGE_QUERY,
  }, context)

  return withLayoutProps({
    path: '/',
  }, data ? {
    props: { data },
    revalidate: REVALIDATION_FREQUENCY,
  } : {
    notFound: true,
  })
}
